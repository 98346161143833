import React from "react";
import Layout from "../../components/Layout";
import {
  Container,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Hero from "../../components/Hero/Hero";
import bloghero from "../../images/dubaiToCanada.svg";
import blogHeroMob from "../../images/bloghero.svg";
import useStyles from "../pageStyles";

const CargoToCanada = () => {
  const classes = useStyles();
  const theme = useTheme();
  const downsm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Layout>
      <Container disableGutters={true}>
        <Hero
          heroTitle="Cargo To Canada From UAE | Freight"
          heroSubTitle=""
          height="80vh"
          backgroundImage={downsm ? blogHeroMob : bloghero}
        />
      </Container>
      <Container disableGutters={true} className={classes.sectionContainer}>
        <Typography
          variant="h4"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          Moving from Dubai to Canada: A Complete Guide for Shipping Household
          and Commercial Items
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          At{" "}
          <Link
            href="https://www.cityboxcargomovers.com/"
            underline="always"
            style={{ fontWeight: "600" }}
          >
            CITYBOX CARGO MOVERS LLC
          </Link>
          , we provide comprehensive options for cargo shipping to Canada,
          whether by sea or air. If you are looking for efficient door-to-door
          cargo services from the UAE to Canada, we offer a range of
          international shipping solutions to suit your needs. Our air freight
          services provide a quick and reliable way to ship small parcels, while
          our ocean freight options are ideal for larger shipments.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          <Link
            href="https://www.cityboxcargomovers.com/services#doortodoorcargo"
            underline="always"
          >
            Door-to-Door Cargo
          </Link>{" "}
          from Dubai to Canada
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          For those seeking fast and reliable cargo services, our air freight
          options are perfect for sending parcels or small shipments from cities
          across the UAE, including{" "}
          <Link
            href="https://en.wikipedia.org/wiki/Dubai"
            underline="always"
            style={{ fontWeight: "600" }}
          >
            Dubai
          </Link>
          , Abu Dhabi, Sharjah, Ajman, Ras Al Khaimah, and Umm Al Quwain. Our
          special air freight rates ensure you get the best value for your
          shipments.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          <Link
            href="https://www.cityboxcargomovers.com/services#seacargo"
            underline="always"
          >
            Sea Cargo to Canada from UAE
          </Link>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          If you need to ship larger or bulk items, our sea cargo services offer
          a cost-effective solution. We provide sea freight from UAE ports to
          major Canadian ports such as Toronto, Montreal, Saint John, Halifax,
          and Nanaimo. Though sea cargo takes longer, it is the best option for
          transporting large volumes at a lower cost.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          <Link
            href="https://www.cityboxcargomovers.com/services#aircargo"
            underline="always"
          >
            Air Cargo from Dubai to Canada
          </Link>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          For those in need of urgent shipments, our air cargo services offer a
          fast, door-to-door solution for any courier or small parcel from the
          UAE. We maintain a strong network to ensure timely and cost-effective
          deliveries from the{" "}
          <Link
            href="https://en.wikipedia.org/wiki/United_Arab_Emirates"
            underline="always"
            style={{ fontWeight: "600" }}
          >
            {" "}
            UAE
          </Link>{" "}
          to Canada. Additionally, we provide chartered and express cargo
          services for larger shipments.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          Why Choose Us for Your Shipment to Canada?
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          CITYBOX CARGO MOVERS LLC is recognized as one of the best shipping
          companies in the UAE for freight forwarding to{" "}
          <Link
            href="https://en.wikipedia.org/wiki/Air_Canada_Cargo"
            underline="always"
            style={{ fontWeight: "600" }}
          >
            Canada
          </Link>{" "}
          and other destinations. We stand out for our competitive prices, short
          transit times, and skilled team of movers, packers, and carpenters who
          can handle all aspects of furniture dismantling, packing, and
          reassembly.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          Exporting Cars from UAE to Canada
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          Exporting a car from{" "}
          <Link
            href="https://www.cityboxcargomovers.com/services#international-relocation"
            underline="always"
            style={{ fontWeight: "600" }}
          >
            Dubai to Canada
          </Link>{" "}
          is straightforward with our assistance. Simply obtain an export
          certificate from the RTA and provide us with the necessary documents.
          Whether you are moving your vehicle nationally or internationally, we
          provide top-notch car transport services to ensure your vehicle
          reaches its destination safely and efficiently.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          Shipping Furniture from Dubai to Canada
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          Shipping furniture from Dubai to Canada is easy with our experienced
          team of movers and carpenters. We expertly dismantle, pack, and
          transport your furniture using high-quality packing materials to
          prevent any damage. Our reliable service, combined with our commitment
          to customer satisfaction, makes us the ideal choice for furniture
          shipping.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          Planning Your Move to Canada
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          Planning ahead is essential when moving to Canada. Determine the type
          of service you need, and consider consulting with a customs agent to
          handle customs clearance smoothly. Whether you are relocating your
          home or business, our cargo services offer maximum safety and
          efficiency in the shipping market.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          Get a Quote for Your Shipment
        </Typography>
        <Typography
          variant="body1"
          gutterBottom={true}
          style={{ marginBottom: "2rem" }}
        >
          To ensure you receive a fair and accurate quote, provide specific
          details about your cargo and shipping needs. Different companies have
          varying pricing methods, but at CITYBOX CARGO MOVERS LLC, we aim to
          offer the most competitive rates for shipping from the UAE to Canada
        </Typography>
        <Typography
          variant="h5"
          gutterBottom={true}
          style={{ marginBottom: "1rem", fontWeight: "600" }}
        >
          Our Key Services:
        </Typography>
        <List style={{ marginBottom: "2rem" }}>
          <ListItem>Cargo from Dubai to Canada</ListItem>
          <ListItem>Shipping from UAE to Canada</ListItem>
          <ListItem>Port-to-port shipments</ListItem>
          <ListItem>Door-to-door cargo services</ListItem>
          <ListItem>Express parcel delivery by courier</ListItem>
          <ListItem>Container shipping (20 feet and 40 feet)</ListItem>
          <ListItem>Minimal transit time</ListItem>
          <ListItem>
            Professional packing and moving services for household and
            commercial items
          </ListItem>
        </List>
        <Typography>
          With CITYBOX CARGO MOVERS LLC, your move to Canada can be smooth,
          efficient, and stress-free.{" "}
          <Link
            href="https://www.cityboxcargomovers.com/contact"
            underline="always"
            style={{ fontWeight: "600" }}
          >
            Contact us today to get started!
          </Link>
        </Typography>
      </Container>
    </Layout>
  );
};

export default CargoToCanada;
